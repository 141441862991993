import React from 'react';
import PropTypes from 'prop-types';
import { css } from '@emotion/react';
import { dateService } from '../../../domain/services/dateService';
import { Image } from '../../Image';
import { SmartLink } from '../../SmartLink';
import { useGeoLocation } from '../../../hooks/useGeoLocation';
import { theme } from '../../../domain/theme';
import { ParagraphWrapper } from '../ParagraphWrapper';

const PromoBannerParagraphInner = ({ data, isAboveTheFold }) => {
  const isVisible =
    useGeoLocation(
      data?.entity?.geolocationFilter,
      data?.entity?.countries,
      data?.entity
    ) &&
    dateService.isWithinDateRange(
      Math.floor(new Date().getTime() / 1000),
      data?.entity?.promoBannerStartDate?.timestamp,
      data?.entity?.promoBannerEndDate?.timestamp
    );

  const isPublished = data?.entity?.isPublished;
  const mobileImage = data?.entity?.PromoBannerMobileImage?.entity;
  const desktopImage = data?.entity?.PromoBannerDesktopImage?.entity;
  const bannerLink = data?.entity?.PromoBannerLink?.uri;
  const bannerLinkTarget = data?.entity?.PromoBannerLinkTarget;
  const bannerClassName = data?.entity?.PromoBannerClass;

  const styles = {
    main: css`
      width: 100%;

      margin: 0 auto;
      margin-bottom: ${theme.spacing('l')};
    `,

    mobileImage: css`
      display: block;
      ${theme.mq.small_desktop} {
        display: none;
      }
    `,

    desktopImage: css`
      display: none;
      ${theme.mq.small_desktop} {
        display: block;
      }
    `
  };

  if (data?.entity && isVisible && isPublished) {
    const mobileImageTag = (
      <div css={desktopImage ? styles.mobileImage : null}>
        <Image
          entity={mobileImage}
          loading={isAboveTheFold ? 'eager' : 'lazy'}
        />
      </div>
    );

    const desktopImageTag = desktopImage ? (
      <div css={styles.desktopImage}>
        <Image
          entity={desktopImage}
          loading={isAboveTheFold ? 'eager' : 'lazy'}
        />
      </div>
    ) : null;

    return bannerLink ? (
      <div css={styles.main}>
        <SmartLink
          to={bannerLink}
          target={bannerLinkTarget || '_self'}
          className={bannerClassName}
        >
          {mobileImageTag}
          {desktopImageTag}
        </SmartLink>
      </div>
    ) : (
      <div className={bannerClassName} css={styles.main}>
        {mobileImageTag}
        {desktopImageTag}
      </div>
    );
  }

  return <div />;
};

PromoBannerParagraphInner.propTypes = {
  data: PropTypes.objectOf(PropTypes.any).isRequired,
  isAboveTheFold: PropTypes.bool.isRequired
};

export const PromoBannerParagraph = ParagraphWrapper(PromoBannerParagraphInner);
